export const environment = {
  production: true,
  authServerUrl: "https://login.warehousing.omniopti.eu/auth",
  languages: [
    {
      name: "SLO 🇸🇮",
      link: "https://sl.dashboard-stage.warehousing.omniopti.eu",
    },
    {
      name: "ENG 🇬🇧",
      link: "https://dashboard-stage.warehousing.omniopti.eu",
    },
  ],
  apiUrl: "https://api.dashboard-stage.warehousing.omniopti.eu",
};
